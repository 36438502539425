<template>
  <PageCard
    pageName="FI Prospecting & CRM"
    :iconClass="['cuis-target', 'card-icon-font']"
    :showHeader="false"
  >
    <template slot="page_content">
      <div
        :class="
          partnerSite ? ['homepage-content partner-site'] : ['homepage-content']
        "
      >
        <section class="  solution-section">
          <div class="">
            <div class="section container-margin" id="1">
              <div class="ps-2">
                <div class="row card-row">
                  <div class="col-9">
                    <h1 class="card-title d-inline-block pr-3 banner-title">
                      FI Prospecting &amp; CRM
                    </h1>
                    <div class="solution-icon same-col soln-img"></div>
                  </div>
                </div>
                <p class="card-text mb-4 soln-desc">
                  Pinpoint Who Needs Your Solutions
                </p>
              </div>
              <FeatureDetailsCard
                title="FIN Query"
                description1="Deploy FIN Query to leverage the powerful combination of firmographics, technographics, performance and contact data for the most precise FI prospecting available anywhere."
                description2="In three easy steps, FIN Query (1) filters prospect institutions, (2) provides additional institution or contact data, and (3) instantly exports the data for laser-focused campaigns."
                button1Text="See Use Cases"
                button1Link="#usecases"
                :galleryImages="VC_SOL_FIN_Q"
              />
              <FeatureDetailsCard
                title="FIN VendorFit"
                description1="Leverage FIN VendorFit to rank core processing vendor-product fit for any U.S. financial institution. The regression analysis integrates over 100 independent variables ranging from asset size to technology purchase behavior."
                description2="Utilize the scoring for new sales to identify higher probability conversion candidates or account management to ID at-risk clients."
                button1Text="See Use Cases"
                button1Link="#usecases"
                :galleryImages="VC_SOL_FIN_V"
              />
            </div>
          </div>
        </section>
        <UseCaseDetails></UseCaseDetails>
      </div>
    </template>
  </PageCard>
</template>

<script>
// global
import { mapState } from 'vuex'
// layout
import PageCard from '@/modules/core/components/layouts/PageCard'
import FeatureDetailsCard from '../components/layouts/FeatureDetailsCard'
import UseCaseDetails from './UseCaseDetails.vue'
// import VC_SOL_FIN_Q1 from '../../../../static/img/solutionImages/VC_SOL_FIN_Q1.png'
// import VC_SOL_FIN_Q2 from '../../../../static/img/solutionImages/VC_SOL_FIN_Q2.png'
// import VC_SOL_FIN_Q3 from '../../../../static/img/solutionImages/VC_SOL_FIN_Q3.png'
// import VC_SOL_FIN_Q4 from '../../../../static/img/solutionImages/VC_SOL_FIN_Q4.png'
// import VC_SOL_FIN_V1 from '../../../../static/img/solutionImages/VC_SOL_FIN_V1.png'
// import VC_SOL_FIN_V2 from '../../../../static/img/solutionImages/VC_SOL_FIN_V2.png'
// import VC_SOL_FIN_V3 from '../../../../static/img/solutionImages/VC_SOL_FIN_V3.png'
// import VC_SOL_FIN_V4 from '../../../../static/img/solutionImages/VC_SOL_FIN_V4.png'
// import VC_SOL_FIN_V5 from '../../../../static/img/solutionImages/VC_SOL_FIN_V5.png'
// import VC_SOL_FIN_V6 from '../../../../static/img/solutionImages/VC_SOL_FIN_V6.png'

export default {
  name: 'ProspectList',
  components: {
    PageCard,
    FeatureDetailsCard,
    UseCaseDetails
  },
  data() {
    return {
      VC_SOL_FIN_Q: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_Q1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_Q2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_Q3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_Q4.png'
      ],
      VC_SOL_FIN_V: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_V1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_V2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_V3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_V4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_V5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_V6.png'
      ]
    }
  },
  created() {
    document.title = 'FI Prospect List'
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    })
  }
}
</script>
<style lang="scss" src="../scss/infoCards.scss"></style>
<style lang="scss" scoped>
.solution-section {
  .soln-img {
    background: url('../../../../static/img/vc-benchmarking.png');
    background-size: cover;
    // margin-bottom: 10px;
  }
}
</style>
